// Descending sorting isn't used, but available from backend

export enum sortByType {
  APPROVAL_DATE_DESCENDING = '1',
  APPROVAL_DATE_ASCENDING = '2',
  MEASURED_RANGE_DESCENDING = '3',
  // MEASURED_RANGE_ASCENDING = '4',
  RANGE_DEVIATION_DESCENDING = '5',
  // RANGE_DEVIATION_ASCENDING = '6',
  CHARGING_SPEED_DESCENDING = '7',
  // CHARGING_SPEED_ASCENDING = '8',
  TRAILER_WEIGHT_DESCENDING = '9',
  // TRAILER_WEIGHT_ASCENDING = '10',
  MAX_PAYLOAD_DESCENDING = '11',
  // MAX_PAYLOAD_ASCENDING = '12',
}
