import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { CarSubListPlaceholder } from './CarSubListPlaceholder';
import { CarModelType, CarVariant as CarVariantType } from '../../../../../types/carModelType';
import { LazyImage } from '../../lazy-image/LazyImage';
import { numberWithSpaces } from '../../../lib';
import { toCloudinaryPath } from '../cloudinary/cloudinary';
import S from '../../../styles/bilguide/StyledBilGuide';

interface Props {
  cars: any;
  isUpdating: boolean;
}

const CarSubList = ({ cars, isUpdating }: Props) => {
  if (isUpdating && cars.length === 0) return <CarSubListPlaceholder key="1-link-list-placeholder" />;

  const modifyCloudinaryImageUrlToThumbnail = (path: string) => path.replace('/upload/v', '/upload/c_scale,w_330/v');

  return (
    <Wrapper>
      {cars.map((car: CarModelType) => {
        if (!car) return null;

        const {
          name,
          brandName,
          modelName,
          modelMinimumPrice_NOK,
          vehicleVariants,
          vehicleModelPictures,
          chassis,
          fuelCategory,
          minWltp_km,
          maxWltp_km,
          preferredPicture,
        } = car;
        const activeVehicleVariants: CarVariantType[] = vehicleVariants || [];
        const displayName = name || `${brandName} ${modelName}`;
        const listImg = vehicleModelPictures?.find((pic: any) => pic.type === 'smallFront');
        const numberOfVehicleVariants = activeVehicleVariants.length;
        const getCarRange = () => {
          if (!minWltp_km && maxWltp_km) {
            return `${maxWltp_km}`;
          }
          return minWltp_km && minWltp_km === maxWltp_km ? `${minWltp_km}` : `${minWltp_km}-${maxWltp_km}`;
        };
        const labelList = [...fuelCategory, ...chassis];
        return isUpdating ? (
          <CarSubListPlaceholder key={`${car.id}-${car.slug}-link-list-placeholder`} />
        ) : (
          <ListElement
            key={`${car.id}-link-list-element`}
            to={{
              pathname: `/bilguiden/bilmodell/${car.slug}`,
            }}
          >
            <ElementImage>
              {preferredPicture?.srcSet?.jpgLarge ? (
                <LazyImage
                  actualSrc={modifyCloudinaryImageUrlToThumbnail(preferredPicture.srcSet.jpgLarge)}
                  displayName={displayName}
                  altText={preferredPicture.alt || preferredPicture.caption}
                />
              ) : (
                <LazyImage
                  actualSrc={toCloudinaryPath(listImg?.path, 'Thumbnail')}
                  displayName={displayName}
                  altText={listImg?.alternateText}
                />
              )}
            </ElementImage>
            <ElementInfo>
              <Text tag="h3" variant={TextVariant.Header3}>
                {displayName}
              </Text>
              <S.LabelList>
                {(minWltp_km > 0 || maxWltp_km > 0) && (
                  <S.LabelListElement key={`${car.id}-label-carRange`}>
                    {getCarRange()} km rekkevidde
                  </S.LabelListElement>
                )}
                {labelList.map((labelType: string) => (
                  <S.LabelListElement key={`${car.id}-label-${labelType}`}>{labelType}</S.LabelListElement>
                ))}
              </S.LabelList>
              <PriceInfoWrapper>
                {modelMinimumPrice_NOK > 0 && (
                  <ElementPrice>
                    fra {numberWithSpaces(modelMinimumPrice_NOK)}
                    ,-
                  </ElementPrice>
                )}
                {numberOfVehicleVariants >= 1 && (
                  <ElementSubInfo>
                    Finnes i {numberOfVehicleVariants} {numberOfVehicleVariants === 1 ? 'variant' : 'varianter'}
                  </ElementSubInfo>
                )}
              </PriceInfoWrapper>
            </ElementInfo>
          </ListElement>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 32px;

  :first-of-type {
    padding-top: 0;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const ListElement = styled((props) => <Link {...props} />)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  padding: 32px 0;

  :first-of-type {
    padding-top: 0;
  }

  border-bottom: 1px solid lightgrey;

  &:hover {
    border-radius: 4px;
    color: ${({ theme }) => (theme && theme.textLink?.color ? theme.textLink?.color : `#1a1a1a`)} !important;
    img,
    svg {
      transform: scale(1.05);
    }
    h3 {
      &::after {
        width: 100%;
      }
    }
  }
`;

const ElementImage = styled.figure`
  overflow: hidden;
  border-radius: ${radius.s};
  margin: 0 ${spacing.space32} 0 0;
  width: 220px;
  position: relative;
  height: 166px;

  @media (max-width: ${breakpoints.s}) {
    width: 110px;
    height: calc(100px / (16 / 9));
    margin-right: ${spacing.space24};
  }

  z-index: 0;

  img {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 106%;
    object-fit: cover;
  }
`;

const ElementInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 {
    width: auto;

    @media (min-width: ${breakpoints.s}) {
      width: max-content;
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 1.125rem;
    }

    position: relative;
    margin: 0;
    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: #ffdb16;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
`;

const PriceInfoWrapper = styled.div`
  margin-top: auto;
  margin-bottom: -6px;
`;

const ElementSubInfo = styled.p`
  font-size: 1rem;
  color: ${nafColor.neutral.neutral6};
  margin: 0;

  @media (max-width: ${breakpoints.s}) {
    font-size: 1rem;
  }
`;

const ElementPrice = styled.strong`
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.125rem;

  @media (max-width: ${breakpoints.s}) {
    font-size: 1rem;
  }
`;

export default CarSubList;
